import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./style.scss";

function App() {
  return (
    <Container className="page-container">
      <Row>
        <Col>
          <Card bg="dark" text="white" className="mb-2">
            <Card.Body>
              <Card.Title>Rosellyne Worrall</Card.Title>
              <Card.Text>
                My website is currently somewhere in the aether. Check back
                soon.
                <br />
                In the meantime, feel free to get in touch.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
